import Vue from "vue";
export default {
    token: () => {
        return Vue.axios
            .post(`auth/authenticate`,
                {
                    "email": "api@oceanringtech.com",
                    "password": "oRt4p!#5"
                })
            .then(res => {
                return res.data.data.token
            })
            .catch(() => null);
    },
}
