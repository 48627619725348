import Vue from "vue";
import getToken from "./getToken";


export default {
  async fetcher(url) {
    let token = await getToken.token();
    if (token) {
      let data = await Vue.axios.get(url, {
        headers: {
          "Authorization": `Bearer ${token}`
        }
      });
      if (data.data.data) {
        data = data.data.data;
      }
      return {
        data
      };
    }
  },
  async fetcherForBlog(url) {
    let token = await getToken.token();
    if (token) {
      let data = await Vue.axios.get(url, {
        headers: {
          "Authorization": `Bearer ${token}`
        }
      });
      if (data.data.data.length) {
        data = data.data.data;
      } else {
        data = "error"
      }
      return {
        data
      };
    }
  },
}
