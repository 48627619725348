<template>
  <div class="default-img" :class="className ? className : ''" :style="{width: width, height: height}" >
    <img :class="{'img--bg': !imgBgNo}" src="../assets/img/event-default.svg" v-if="images_error" alt=""/>
    <div v-if="images_data" :class="{'different-img': different}">
      <img :class="{'img--bg': !imgBgNo}" :src="images_data.data['data']['full_url']" v-if="images_data" alt=""/>
      <img :class="{'img--bg': !imgBgNo}" class="img--bg" v-else src="../assets/img/event-default.svg" alt="">
    </div>
    <div v-if="images_data && different" :class="{'different-img': different}">
      <img class="bg-img" :src="images_data.data['data']['full_url']" v-if="images_data" alt=""/>
      <img class="bg-img" v-else src="../assets/img/event-default.svg" alt="">
    </div>
  </div>
</template>

<script>
  import useSWRV from "swrv";
  import api from "../services/api";

    export default {
      name: "DefaultImage",
      props: ['id', 'width', 'height', 'className', 'defaultImg', 'different', 'imgBgNo'],
      setup(props) {
        if (props.id) {
          let {
            data: images_data,
            error: images_error,
            isValidating: images_isValidating
          } = useSWRV(() => `/files/${props.id}?status=published`, api.fetcher);

          return {
            images_data,
            images_error,
            images_isValidating,
          }
        } else {
          let images_data = undefined,
              images_error = 'error';

          return {
            images_data,
            images_error
          }
        }
      },
    }
</script>

<style scoped lang="scss">
  .border-radius {
    border-radius: 50%;
  }

  .default-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../assets/img/image-loader.gif')!important;
    background-repeat: no-repeat!important;
    background-position: 50%!important;
    background-size: cover!important;
  }

  .default-img img {
    z-index: 1;
  }

  .donate-page img {
    object-fit: contain !important;
    object-position: center !important;
  }

  #events-details {
    .default-img {
      min-height: 600px;
      img {
        width: 100%;
      }
    }
  }

  .different-img .img--bg {
    width: auto;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
  }

  .bg-img {
    position: absolute;
    z-index: 1;
    top: -22%;
    left: -22%;
    width: 150%;
    height: 150%;
    margin: 0 !important;
    filter: blur(80px);
    -webkit-filter: blur(80px);
  }

  @media (max-width: 991px) {
    #events-details .default-img {
      min-height: auto;
    }
  }

  @media (max-width: 768px) {
    #about .default-img {
      height: 400px !important;
    }
  }
</style>
