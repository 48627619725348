<template>
  <img src="../assets/img/loading-animation.gif" width="80" alt="">
</template>

<script>
  export default {
    name: "Loading"
  }
</script>

<style scoped>

</style>
